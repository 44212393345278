import * as React from "react";

import Layout from "../components/layout";
import {Link} from "gatsby";

const NotFound = () => {
    return (
        <Layout header="dark">
            <section className="hero-banner hero-banner--dark hero-banner--regular rel-wrap">
                <div className="grid-container rel-content">
                    <div className="grid-x grid-margin-x align-middle">
                        <div className={`cell medium-9 align-self-middle rel-content`}>
                            <div className="hero-banner__content hero-banner__content--notfound">
                                <div>
                                    <h1>404: Page Not Found</h1>
                                    <p>Keep on looking...</p>
                                    <p>Double check the URL or head back to the </p>
                                </div>
                                <Link to={'/'} className="button primary">Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
};

export default NotFound